import React, { useEffect, useState } from 'react';
import AdminLayout from '../components/common/AdminLayout';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import MessagePopup from './MessagePopup'; // Import the popup component

const User_Profile = () => {
    const [accountabilityPartners, setAccountabilityPartners] = useState([]);
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showSelect, setShowSelect] = useState(false);
    const [showPopup, setShowPopup] = useState(false); // State for popup
    const [successMessage, setSuccessMessage] = useState(null); // State to hold the success message
    const navigate = useNavigate();

    const mentorEmail = selectedPartner?.email;
 
    useEffect(() => {
        const loggedIn = localStorage.getItem('isLoggedIn');
        if (!loggedIn) {
            navigate('/login'); // Redirect to login if not logged in
        }
    }, [navigate]);

    useEffect(() => {
        const fetchAccountabilityPartners = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const userId = user.user_id;

                const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/get-accountability-partners', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: userId }),
                });

                const data = await response.json();
                if (data.status) {
                    const selected = data.data.find(partner => partner.is_selected);
                    if (selected) {
                        setSelectedPartner(selected);
                    }
                    setAccountabilityPartners(data.data);
                } else {
                    console.error("No partners found or status is false.");
                }
            } catch (error) {
                console.error("Error fetching accountability partners:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchAccountabilityPartners();
    }, []);

    const handleChangeMentor = () => {
        setShowSelect(true);
    };

    const updateSelectedPartner = async (partnerId) => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const userId = user.user_id;
    
            const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/select-partner', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: userId,
                    partner_id: partnerId
                }),
            });
    
            const result = await response.json();
            if (result.status) {
                // Find the partner object and set it as the selectedPartner
                const selected = accountabilityPartners.find(partner => partner.id === partnerId);
                setSelectedPartner(selected); // Set the full partner object
    
                // Display success message
                setSuccessMessage("Mentor updated successfully!"); 
    
                // Hide the success message after 3 seconds
                setTimeout(() => setSuccessMessage(null), 3000); 
            } else {
                console.error("Failed to update partner.");
            }
        } catch (error) {
            console.error("Error updating selected partner:", error);
        }
    };
    
    
    const handleSendMessage = async (subject, message) => {
        try {
            // Assuming you already have the `selectedPartner` which contains the accountability partner details
            const accountabilityPartnerId = selectedPartner?.id; // Get the ID of the selected partner
            const user = JSON.parse(localStorage.getItem('user'));
            const userId = user?.user_id; // Get the logged-in user's ID
    
            // If both userId and accountabilityPartnerId are available
            if (userId && accountabilityPartnerId) {
                const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/email_messages', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user_id: userId,
                        accountability_partner_id: accountabilityPartnerId,
                        subject: subject,
                        chat_message: message, // Pass the message here as chat_message
                    }),
                });
    
                const result = await response.json();
    
                // Handle the response from the API (e.g., show success message or handle error)
                if (result.status) {
                    setSuccessMessage("Message sent successfully!");
                    setTimeout(() => setSuccessMessage(null), 3000); // Hide success message after 3 seconds
                } else {
                    console.error("Failed to send message:", result.message);
                }
            } else {
                console.error("User ID or Accountability Partner ID is missing");
            }
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };
    

    return (
        <AdminLayout>
            <section className="assessments_listing">
                <div className="container-fluid">
                    {/* Success Message at the top */}
                    <p>The Mentor feature of DISC AI Coach is to enable Coaches, Training Managers, HR Professionals, Team Leaders, Sales Managers, and so on to act as Accountability Partners/Mentors for individuals under their charge and guidance. The user identifies the Mentor in the DISC AI Coach app and opens up direct communication between Mentor and Coachee.</p>
                    
                    <div className="bg-card">
                        {loading ? (
                        <div class="loader"></div> 
                        ) : (
                            <div className="row">
                                <div className="col-md-12 mentor-block">
                                    <div className="mentor-image">
                                        <img src="https://www.extendeddiscsolutions.org/wp-content/uploads/2024/10/contact_us_banner-1.svg" alt="logout_icon" />
                                    </div>
                                    <div className="mentor-content">
                                        <h5>Mentor</h5>
                                        <div className="heading_assessments">
                                        <h2>{selectedPartner && selectedPartner.name ? selectedPartner.name : 'No Accountability Partner Selected'}</h2>
                                            <div className="send-message-button">
                                                <Link onClick={() => setShowPopup(true)} className="send-message" >Send Message</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>


                    <div className="bg-card">
                        <div className="row">
                            <div className="col-md-12">
                                {successMessage && (
                                    <div className="success-message">
                                        {successMessage}
                                    </div>
                                )}
                                <div className="heading_assessments">
                                    <h5>Accountability Partner</h5>
                                    <p>Select a Mentor of your choice to keep you motivated through your journey.</p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {!showSelect && (
                                    <button 
                                        className="change-mentor-button" 
                                        onClick={handleChangeMentor}
                                    >
                                        CHANGE MENTOR
                                    </button>
                                )}
                                {showSelect && (
                                    <select 
                                        id="accountability-partners" 
                                        name="accountability-partners" 
                                        onChange={(e) => updateSelectedPartner(parseInt(e.target.value))}
                                    >
                                        <option value="">Select an Accountability Partner</option>
                                        {accountabilityPartners.length > 0 ? (
                                            accountabilityPartners.map((data) => (
                                                <option 
                                                    key={data.id} 
                                                    value={data.id} 
                                                    selected={data.is_selected ? 'selected' : ''} 
                                                >
                                                    {data.name}
                                                </option>
                                            ))
                                        ) : (
                                            <option disabled>No Accountability Partners Available</option>
                                        )}
                                    </select>
                                )}

                                {showPopup && (
                                    <MessagePopup
                                        mentorEmail={mentorEmail}
                                        onClose={() => setShowPopup(false)}
                                        onSend={handleSendMessage}
                                    />
                                )}
                            </div>
                        </div>                    
                    </div>    
                    <div className="next-button-div">
                        <Link className="next-button" to="/focus-areas">NEXT</Link>
                    </div>
                </div>
            </section>
        </AdminLayout>
    );
};

export default User_Profile;
