import { useState, useEffect } from 'react';
import AdminLayout from '../components/common/AdminLayout';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const SalesResources = () => {
    const navigate = useNavigate();
    const [videoData, setVideoData] = useState([]); // Store all loaded videos
    const [page, setPage] = useState(1); // Track current page
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [error, setError] = useState(null); // Error state
    const [hasMore, setHasMore] = useState(true); // If more pages are available
    const [toStyle, setToStyle] = useState(''); // Filter for "To Style"
    const [fromStyle, setFromStyle] = useState(''); // Filter for "From Style"

    useEffect(() => {
        const loggedIn = localStorage.getItem('isLoggedIn');
        if (!loggedIn) {
            navigate('/login'); // Redirect to login if not logged in
        }
    }, [navigate]);

    useEffect(() => {
        loadVideos(); // Initial load
    }, [page, toStyle, fromStyle]);

    useEffect(() => {
        const handleScroll = () => {
            // Check if user has scrolled to the bottom of the page
            if (
                window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight
            ) {
                if (hasMore && !isLoading) {
                    setPage((prevPage) => prevPage + 1); // Load next page
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isLoading, hasMore]);

    const loadVideos = async () => {
        setIsLoading(true);
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const userId = user.user_id;

            const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/resources', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'video',
                    category_name: 'sales',
                    user_id: userId,
                    search: '',
                    content_type: fromStyle,
                    page: page,
                }),
            });

            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }

            const data = await response.json();
            console.log(data);
            if (data.status && Array.isArray(data.data)) {
                setVideoData((prevData) => (page === 1 ? data.data : [...prevData, ...data.data]));
                setHasMore(data.pagination.has_more); // Check if more data is available
            } else {
                setError("No videos found or status is false.");
                setHasMore(false); // No more data
            }
        } catch (error) {
            console.error("Error fetching videos:", error);
            setError("Failed to load videos.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleToStyleChange = (e) => {
        setToStyle(e.target.value);
        setPage(1); // Reset to the first page when filter changes
        setVideoData([]); // Clear previous video data on filter change
    };

    const handleFromStyleChange = (e) => {
        setFromStyle(e.target.value);
        setPage(1); // Reset to the first page when filter changes
        setVideoData([]); // Clear previous video data on filter change
    };

    const getShortDescription = (description) => {
        const words = description.split(' ');
        return words.slice(0, 10).join(' ') + (words.length > 10 ? '...' : '');
    };

    return (
        <AdminLayout>
            <h3>Resources</h3>
            <p>The Resources tab hosts an ever-expanding library of rich, developmental content across a range of subject areas. Use the search and funnel to fine tune your searches.\nNote: Searches are confined to DISC AI Coach curated content.</p>
            <div className='filter-block'>
                <div className='filter-right-block'>
                    <label>From Style</label>
                    <select value={fromStyle} onChange={handleFromStyleChange}>
                        <option value="">Select From Style</option>
                        <option value="D">Dominance (D)</option>
                        <option value="I">Influence (I)</option>
                        <option value="S">Steadiness (S)</option>
                        <option value="C">Conscientiousness (C)</option>
                        <option value="All">All</option>
                    </select>
                </div>
            </div>
            <div className="video-gallery">
                {isLoading && page === 1 ? (
                    <div className="loader"></div> 
                ) : error ? (
                    <p>{error}</p>
                ) : videoData.length > 0 ? (
                    videoData.map((video) => (
                        <div className="video-card" key={video.id}>
                            <Link to={`/video/${video.id}`} rel="noopener noreferrer">
                                <img src={video.thumbnail} alt={video.title} className="video-thumbnail" />
                            </Link>
                            <h3 className="video-title">{video.title}</h3>
                            <p className="description">{getShortDescription(video.description)}</p>
                        </div>
                    ))
                ) : (
                    <p>No videos available.</p>
                )}
            </div>
            {isLoading && page > 1 && <div className='bg-card'><div className="loader"></div></div>}
        </AdminLayout>
    );
};

export default SalesResources;
