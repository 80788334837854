import React, { useState, useEffect } from 'react';
import AdminLayout from '../components/common/AdminLayout';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Notifications = () => {
    const navigate = useNavigate();
    const [notiStatus, setNotiStatus] = useState(0); // Default: disabled
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true); // Added loading state to track fetching status

    useEffect(() => {
        // Check if user is logged in
        const loggedIn = localStorage.getItem('isLoggedIn');
        if (!loggedIn) {
            navigate('/login'); // Redirect to login if not logged in
            return;
        }

        // Fetch the notification status if the user is logged in
        const fetchNotificationStatus = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                if (!user || !user.user_id) {
                    console.error('Cannot update notification status: user ID is missing.');
                    return;
                }

                const response = await axios.post(
                    'https://www.extendeddiscsolutions.org/wp-json/custom/v1/resources',
                    {
                        "type": "video",
                        "category_name": "how_we_work",
                        "search": "",
                        "page": 1,
                        user_id: user.user_id,
                        "content_type": ""
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (response.status === 200 && response.data) {
                    setNotiStatus(response.data.noti_status); // Set the fetched status
                } else {
                    console.error('Invalid response format:', response);
                }
            } catch (error) {
                console.error('Failed to fetch notification status:', error.response?.data || error.message);
            }
        };

        fetchNotificationStatus(); // Call the function
    }, [navigate]);

    const handleToggle = async (status) => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            if (!user || !user.user_id) {
                console.error('Cannot update notification status: user ID is missing.');
                return;
            }

            const response = await axios.post(
                'https://www.extendeddiscsolutions.org/wp-json/custom/v1/notifications_status',
                {
                    user_id: user.user_id,
                    noti_status: status,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.status === 200) {
                setNotiStatus(status); // Update the state with the new status
                console.log('Notification status updated successfully:', response.data);
            } else {
                console.error('Failed response:', response);
            }
        } catch (error) {
            console.error('Failed to update notification status:', error.response?.data || error.message);
        }
    };

    const handleChange = (event) => {
        const status = parseInt(event.target.value, 10); // Convert value to integer
        handleToggle(status); // Update the notification status
    };

    const handleBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    useEffect(() => {
        const getNotificationData = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                if (!user || !user.user_id) {
                    console.error('Cannot update notification status: user ID is missing.');
                    return;
                }

                // Set loading to true before fetching
                setLoading(true);

                const response = await axios.post(
                    'https://www.extendeddiscsolutions.org/wp-json/custom/v1/notifications',
                    {
                        user_id: user.user_id,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (response.status === 200) {
                    // Access the `data` array inside the response
                    setNotifications(response.data.data || []);
                    console.log('Notification data:', response.data.data);
                } else {
                    console.error('Failed response:', response);
                }
            } catch (error) {
                console.error('Failed to update notification status:', error.response?.data || error.message);
            } finally {
                // Set loading to false once data is fetched
                setLoading(false);
            }
        };

        getNotificationData(); // Call the function
    }, [navigate]);

    // Handle redirect based on notification type
    const handleRedirect = (type) => {
        // Redirect to the URL associated with the notification type
        const redirectUrl = `${type}`; // Modify the URL pattern as needed
        window.location.href = redirectUrl;
    };

    return (
        <AdminLayout>
            <div className="notifications-header d-flex justify-content-between align-items-center">
                {/* Back Button */}
                <button className="button" onClick={handleBack}>
                    Back
                </button>

                {/* Title */}
                <h3 className="text-center">Notifications</h3>

                {/* Toggle Switch */}
                <div className="notification-toggle">
                    <label className="switch">
                        <input
                            type="checkbox"
                            checked={notiStatus == 1}
                            onChange={() => handleToggle(notiStatus == 1 ? 0 : 1)}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>

            <div className="notifications-content mt-4 bg-card">
                {loading ? (
                    <div class="loader"></div>  // Show loader while data is loading
                ) : notifications.length > 0 ? (
                    notifications.map((notification) => (
                        <div
                            key={notification.id}
                            className="notification-item"
                            onClick={() => handleRedirect(notification.type)}
                            style={{ cursor: 'pointer' }}
                        >
                            <div className="message" style={{ display: 'inline-block', width: '70%' }}>
                                {notification.message}
                            </div>
                            <div className="date" style={{ display: 'inline-block', width: '30%', textAlign: 'right' }}>
                                {new Date(notification.date).toLocaleDateString()}
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No notifications available.</p>
                )}
            </div>
        </AdminLayout>
    );
};

export default Notifications;
