import { useState, useEffect, useCallback } from "react";
import AdminLayout from "../components/common/AdminLayout";
import { useNavigate } from "react-router-dom";

const LeadershipProgram = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Overall loading state (for categories)
    const [isLoadingTips, setIsLoadingTips] = useState(false); // Loading state for tips
    const [error, setError] = useState(null); // Error state
    const [categories, setCategories] = useState([]); // Categories
    const [selectedCategory, setSelectedCategory] = useState(""); // Selected category
    const [tips, setTips] = useState([]); // Tips
    const [currentPage, setCurrentPage] = useState(1); // Current page for tips
    const [hasMoreTips, setHasMoreTips] = useState(false); // If more tips are available

    // Redirect to login if not logged in
    useEffect(() => {
        const loggedIn = localStorage.getItem("isLoggedIn");
        if (!loggedIn) {
            navigate("/login");
        }
    }, [navigate]);

    // Fetch categories for the program
    useEffect(() => {
        const fetchCategories = async () => {
            setIsLoading(true); // Show loading while fetching categories
            try {
                const response = await fetch(
                    "https://www.extendeddiscsolutions.org/wp-json/custom/v1/get_categories_by_program",
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ program_id: "leadership_program" }),
                    }
                );
        
                if (!response.ok) {
                    throw new Error(`Failed to fetch categories: ${response.status}`);
                }
        
                const data = await response.json();
                console.log("Categories API response:", data); // Debug log to check API structure
        
                if (data.status) {
                    // Accessing categories from data.message.competences
                    if (data.data && data.data.competences && data.data.competences.length > 0) {
                        setCategories(data.data.competences);
                        setSelectedCategory(data.data.competences[0].category_name); // Set the first category by default
                    } else {
                        setError("No categories found.");
                    }
                } else {
                    setError("No categories found.");
                }
            } catch (err) {
                setError("Failed to load categories.");
                console.error(err);
            } finally {
                setIsLoading(false); // Hide loading once categories are fetched
            }
        };
        fetchCategories();
    }, []);

    useEffect(() => {
        if (!selectedCategory) return; // Return early if no category is selected
    
        const fetchTips = async () => {
            setIsLoadingTips(true); // Show loading for tips only
            try {
                console.log("Fetching tips for category:", selectedCategory); // Debug log
                const response = await fetch(
                    "https://www.extendeddiscsolutions.org/wp-json/custom/v1/get_tips",
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            competency_name: selectedCategory, // Use category_name here
                            program_id: "leadership_program",
                            page: currentPage,
                        }),
                    }
                );
    
                const data = await response.json();
                console.log("Tips API response:", data); // Log response
    
                if (data.status && data.data && Array.isArray(data.data.tips)) {
                    setTips((prevTips) =>
                        currentPage === 1 ? data.data.tips : [...prevTips, ...data.data.tips]
                    );
                    setHasMoreTips(data.data.has_more);
                } else {
                    setTips([]); // Clear tips if none found or invalid data
                }
            } catch (err) {
                setError("Failed to load tips.");
                console.error(err);
            } finally {
                setIsLoadingTips(false); // Hide loading for tips once data is fetched
            }
        };
    
        fetchTips();
    }, [selectedCategory, currentPage]); // Re-fetch tips whenever selectedCategory changes

    // Handle category change
    const handleCategoryChange = (event) => {
        // Find the selected category object by its ID
        const selectedCategoryObj = categories.find(
            (category) => category.id === event.target.value
        );

        // Set the category name as the selected value (for fetching tips)
        setSelectedCategory(selectedCategoryObj.category_name);
        setCurrentPage(1); // Reset to page 1 when category changes
        setTips([]); // Clear existing tips
    };

    // Infinite scroll handler
    const handleScroll = useCallback(() => {
        const bottom = window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight;
        if (bottom && !isLoadingTips && hasMoreTips) {
            setCurrentPage((prevPage) => prevPage + 1); // Load more tips when the bottom is reached
        }
    }, [isLoadingTips, hasMoreTips]);

    // Attach scroll event listener
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    return (
        <>
            <h3>Leadership Program</h3>
            <p>
                Use the drop-down menu to access coaching on any of the range of
                competencies from your behavioral assessment. Inserted videos add
                insights, press on the links to open them.
            </p>
            <div className="bg-card">
                {error && <p style={{ color: "red" }}>{error}</p>}
                {isLoading && <div className="loader"></div>}
                {!isLoading && categories && categories.length > 0 && (
                    <div>
                        {/* Dropdown for categories */}
                        <select
                            id="category-select"
                            value={categories.find(category => category.category_name === selectedCategory)?.id || ""}
                            onChange={handleCategoryChange}
                            style={{ marginBottom: "1em" }}
                        >
                            {categories.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.category_name}
                                </option>
                            ))}
                        </select>

                        {/* Display tips */}
                        <div>
                            {tips.map((tip) => (
                                <div key={tip.id} style={{ marginBottom: "1em" }}>
                                    <h5>{tip.category_name}</h5>
                                    <p>{tip.content}</p>
                                </div>
                            ))}

                            {/* Show loading spinner if more tips are loading */}
                            {isLoadingTips && <div className="loader"></div>}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default LeadershipProgram;
