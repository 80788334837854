import React, { useEffect, useRef, useState } from 'react';
import AdminLayout from '../components/common/AdminLayout';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const SalesReport = () => {
    const navigate = useNavigate();
    const [leadershipReport, setLeadershipReport] = useState(null);
    const [leadershipReportPagination, setLeadershipReportPaginations] = useState({});
    const [loading, setLoading] = useState(true);
    const [openSections, setOpenSections] = useState({});
    const [username, setUsername] = useState('');
    const [page, setPage] = useState(1); // Track current page
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [isScrollEventAttached, setIsScrollEventAttached] = useState(false);

    useEffect(() => {
        const loggedIn = localStorage.getItem('isLoggedIn');
        if (!loggedIn) {
            navigate('/login'); // Redirect to login if not logged in
        }
    }, [navigate]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setUsername(user.username);
        }
    }, []);

    const fetchLeadershipReport = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const userId = user.user_id;

            const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/sales_report', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: userId }),
            });

            const data = await response.json();
            console.log("Leadership Report Data:", data); // Log the data
            if (data.status) {
                setLeadershipReport(data);
            } else {
                setLeadershipReport(null);
            }
        } catch (error) {
            console.error("Error fetching Report", error);
            setLeadershipReport(null);
        } finally {
            setLoading(false);
        }
    };

    const toggleSection = (id) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const handleCompetencyClick = (competency) => {
        if (!leadershipReportPagination[competency.name]) {
            setLeadershipReportPaginations((prev) => ({
                ...prev,
                [competency.name]: {
                    page: 1, // Initialize with page 1
                    items: [], 
                    hasMore: true,
                    loading: false, // Add loading flag
                },
            }));
        }
    
        setOpenSections((prevState) => ({
            ...prevState,
            [competency.name]: !prevState[competency.name],
        }));
    };
      
    const fetchLeadershipReportPaginations = async (competencyName, page) => {
        try {
            // Show loader for pagination
            setIsLoading(true);  // Display loader for paginated data fetch
    
            const user = JSON.parse(localStorage.getItem('user'));
            const userId = user.user_id;
    
            const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/report_pagination', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    user_id: userId,
                    name: competencyName,
                    page: page,
                }),
            });
    
            const data = await response.json();
            console.log("Pagination Data:", data);
    
            if (data.status) {
                setLeadershipReportPaginations((prevData) => ({
                    ...prevData,
                    [competencyName]: {
                        items: [...(prevData[competencyName]?.items || []), ...data.data.competences],
                        hasMore: data.data.has_more_competences,
                        page: page, // Update page number
                    },
                }));
            } else {
                console.error(`Failed to fetch data for Page ${page}`);
            }
        } catch (error) {
            console.error("Error fetching Report Pagination", error);
        } finally {
            // Hide loader after the pagination data is fetched
            setIsLoading(false);
        }
    };

    const scrollRefs = useRef({});

    const handleScroll = (competencyName, scrollDiv) => {
        const paginationData = leadershipReportPagination[competencyName] || {};
        if (!paginationData || paginationData.loading || !paginationData.hasMore) return;
    
        const { scrollTop, scrollHeight, clientHeight } = scrollDiv;
        const bottomReached = scrollTop + clientHeight >= scrollHeight - 10;
    
        if (bottomReached) {
            const currentPage = paginationData.page || 1;
            const nextPage = currentPage + 1;
    
            console.log("Loading page:", nextPage);
    
            setLeadershipReportPaginations((prev) => ({
                ...prev,
                [competencyName]: {
                    ...prev[competencyName],
                    loading: true,
                },
            }));
    
            fetchLeadershipReportPaginations(competencyName, nextPage).then(() => {
                setLeadershipReportPaginations((prev) => ({
                    ...prev,
                    [competencyName]: {
                        ...prev[competencyName],
                        page: nextPage,
                        loading: false,
                    },
                }));
            });
        }
    }
    
    const handleCompetencyClickNavigate = (competency) => {
        navigate('/competency-details', { state: { competency } });
    };
    
    useEffect(() => {
        fetchLeadershipReport();
    }, []);

    const userName = 'iitjam'; // You can get this from props, context, or state

    return (
        <AdminLayout>
            {loading ? (
                <div className="loader"></div>
            ) : leadershipReport === null ? (
                <div className="no-report-message">
                    <p>The assessment report is not ready yet.</p>
                </div>
            ) : (
                <section className="assessments_listing">
                    <div id="content">
                        <div className="report-content">
                            <div className='bg-card'>
                                <div className="container-fluid first_report_dowenloadvs">
                                    <div className="report-head">
                                        <h3>{leadershipReport.data.report_title}</h3>
                                        <Link
                                            to={leadershipReport.data.report_pdf_link}
                                            className="download-report"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Download Report
                                        </Link>
                                    </div>
    
                                    <p><span>D</span><span>I</span><span>S</span><span>I</span></p>
    
                                    <div className='competency-data'>
                                    {leadershipReport.data.main_categories.map(category => (
                                        <div key={category.id}>
                                        <h5 onClick={() => toggleSection(category.id)}>
                                            {category.name}
                                        </h5>

                                        {openSections[category.id] && (
                                            <div className='category-data' style={{ height: "250px", overflow: "auto" }}>
                                            {/* Replace $u_name with the actual user name in the category content */}
                                            <p dangerouslySetInnerHTML={{
                                                __html: category.content.replace(/\$u_name/g, userName)
                                            }}></p>

                                            {/* Check if the category has subcategories and display them */}
                                            {category.subcategories.length > 0 && (
                                                <div className='subcategories'>
                                                {category.subcategories.map(subcategory => (
                                                    <div key={subcategory.id}>
                                                    <h6>{subcategory.name}</h6>
                                                    <p>{subcategory.content.replace(/\$u_name/g, userName)}</p>
                                                    </div>
                                                ))}
                                                </div>
                                            )}
                                            </div>
                                        )}
                                        </div>
                                    ))}
                                    </div>
    
                                    {leadershipReport.data.competences.map((competence) => {
                                        const paginationData = leadershipReportPagination[competence.name] || {}; // Get paginated data for the competency
                                        const items = paginationData.items || []; // Get paginated items
    
                                        return (
                                            <div key={competence.name}>
                                                <h5 onClick={() => handleCompetencyClick(competence)}>
                                                    {competence.name}
                                                </h5>
    
                                                {openSections[competence.name] && (
                                                    <div onScroll={() => handleScroll(competence.name, scrollRefs.current[competence.name])} ref={(el) => (scrollRefs.current[competence.name] = el)} className="category-data" style={{ height: "250px", overflow: "auto" }}>
                                                        {/* Render competence items */}
                                                        {competence.my_competences.map((comp, index) => (
                                                            <div key={index}>
                                                                <h4>{comp.name}</h4>
                                                                <ul className="charts">
                                                                    {Array.from({ length: 11 }, (_, i) => i - 5).map((score) => (
                                                                        <li
                                                                            key={score}
                                                                            className={score === comp.person_score ? "slected_li" : ""}
                                                                        >
                                                                            <button onClick={() => handleCompetencyClickNavigate(comp)}>
                                                                                {score}
                                                                            </button>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        ))}

                                                        {paginationData.items.length > 0 && (
                                                            <div className="pagination-items">
                                                                {paginationData.items.map((item, index) => (
                                                                    <div key={index}>
                                                                        {Array.isArray(item.my_competences) && item.my_competences.length > 0 ? (
                                                                            item.my_competences.map((competence, idx) => (
                                                                                <div key={idx}>
                                                                                    <h4>{competence.name}</h4>
                                                                                    <ul className="charts">
                                                                                        {Array.from({ length: 11 }, (_, i) => i - 5).map((score) => (
                                                                                            <li
                                                                                                key={score}
                                                                                                className={score === competence.person_score ? "slected_li" : ""}
                                                                                            >
                                                                                                <button onClick={() => handleCompetencyClickNavigate(competence)}>
                                                                                                    {score}
                                                                                                </button>
                                                                                            </li>
                                                                                        ))}
                                                                                    </ul>
                                                                                </div>
                                                                            ))
                                                                        ) : (
                                                                            <p>No competences available</p>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                        {/* Show loader for each item if pagination data is loading */}
                                                        {isLoading && <div className="loader"></div>}
                                                        {/* Ensure items are displayed under the correct competency */}

                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </AdminLayout>
    );
};

export default SalesReport;    
