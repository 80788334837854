import { useState, useEffect } from 'react';
import AdminLayout from '../components/common/AdminLayout';
import { useNavigate } from 'react-router-dom';

const InstantCoach = () => {
    const navigate = useNavigate();
    const [videoData, setVideoData] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [toStyle, setToStyle] = useState('');
    const [fromStyle, setFromStyle] = useState('');

    useEffect(() => {
        const loggedIn = localStorage.getItem('isLoggedIn');
        if (!loggedIn) {
            navigate('/login');
        }
    }, [navigate]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        const userDiscType = user ? user.disc_type : '';
        setToStyle(userDiscType);
    }, []);

    useEffect(() => {
        loadVideos();
    }, [page, toStyle, fromStyle]);

    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + document.documentElement.scrollTop >=
                    document.documentElement.offsetHeight - 200 &&
                !isLoading &&
                hasMore
            ) {
                setPage((prevPage) => prevPage + 1);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isLoading, hasMore]);

    const loadVideos = async () => {
        setIsLoading(true);
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const userId = user ? user.user_id : '';

            const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/resources', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'video',
                    category_name: 'quick_connect',
                    user_id: userId,
                    search: toStyle,
                    content_type: fromStyle,
                    page: page,
                }),
            });

            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }

            const data = await response.json();
            if (data.status && Array.isArray(data.data)) {
                setVideoData((prevData) => (page === 1 ? data.data : [...prevData, ...data.data]));
                setHasMore(data.pagination.has_more);
            } else {
                setError("No videos found or status is false.");
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching videos:", error);
            setError("Failed to load videos.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleToStyleChange = (e) => {
        setToStyle(e.target.value);
        setPage(1);
        setVideoData([]);
    };

    const handleFromStyleChange = (e) => {
        setFromStyle(e.target.value);
        setPage(1);
        setVideoData([]);
    };

    const getShortDescription = (description) => {
        const words = description.split(' ');
        return words.slice(0, 10).join(' ') + (words.length > 10 ? '...' : '');
    };

    return (
        <AdminLayout>
            <h3>Quick connect</h3>
            <p>Increase your impact and influence from the outset by following expert coaching as to how to work effectively and harmoniously with any behavioral style from your profile. Use the search and funnel filters to fine-tune your searches.</p>
            <div className='filter-block'>
                <div className='filter-left-block'>
                    <label>To Style</label>
                    <select value={toStyle} onChange={handleToStyleChange}>
                        <option value="">Select To Style</option>
                        <option value="DI">DI</option>
                        <option value="DS">DS</option>
                        <option value="DC">DC</option>
                        <option value="ID">ID</option>
                        <option value="IS">IS</option>
                        <option value="IC">IC</option>
                        <option value="SD">SD</option>
                        <option value="SI">SI</option>
                        <option value="SC">SC</option>
                        <option value="CI">CI</option>
                        <option value="CS">CS</option>
                    </select>
                </div>
                <div className='filter-right-block'>
                    <label>From Style</label>
                    <select value={fromStyle} onChange={handleFromStyleChange}>
                        <option value="">Select From Style</option>
                        <option value="D">Dominance (D)</option>
                        <option value="I">Influence (I)</option>
                        <option value="S">Steadiness (S)</option>
                        <option value="C">Conscientiousness (C)</option>
                        <option value="All">All</option>
                    </select>
                </div>
            </div>
            <div className="video-gallery">
                {isLoading && page === 1 ? (
                    <div className="loader"></div>
                ) : error ? (
                    <p>{error}</p>
                ) : videoData.length > 0 ? (
                    videoData.map((video) => (
                        <div className="video-card" key={video.id}>
                            <a href={video.name} target="_blank" rel="noopener noreferrer">
                                <img src={video.thumbnail} alt={video.title} className="video-thumbnail" />
                            </a>
                            <h3 className="video-title">{video.title}</h3>
                            <p className="description">{getShortDescription(video.description)}</p>
                        </div>
                    ))
                ) : (
                    <p>No videos available.</p>
                )}
            </div>
            {isLoading && page > 1 && <div className="loader"></div>}
        </AdminLayout>
    );
};

export default InstantCoach;
