import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdminLayout from '../components/common/AdminLayout';

const VideoPlayer = () => {
  const { videoId } = useParams();
  const navigate = useNavigate();
  const [videoData, setVideoData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [player, setPlayer] = useState(null);

  // Create a ref for the youtube-player div
  const youtubePlayerRef = useRef(null);

  const initializePlayer = () => {
    if (videoData && !player) { // Check if videoData is available and player is not already initialized
      const extractedVideoId = extractVideoId(videoData.resource_name);
      if (extractedVideoId) {
        console.log('Initializing YouTube player with videoId:', extractedVideoId);
  
        // Adding setTimeout before checking the youtubePlayerRef
        setTimeout(() => {
          // Check if the youtubePlayerRef (which points to the div) exists before initializing the player
          if (youtubePlayerRef.current) {
            const newPlayer = new window.YT.Player(youtubePlayerRef.current, {
              videoId: extractedVideoId,
              events: {
                onStateChange: handlePlayerStateChange,
              },
            });
            setPlayer(newPlayer);  // Set player only once
          } else {
            console.error('YouTube player div not found');
          }
        }, 1500); // Delay of 1500ms (1.5 seconds) before attempting to initialize the player
      }
    }
  };
  
  
  // Load YouTube API dynamically
  useEffect(() => {
    const loadYouTubeAPI = () => {
      if (window.YT) {
        // YT is already loaded, proceed with initialization
        initializePlayer();
      } else {
        const script = document.createElement('script');
        script.src = "https://www.youtube.com/iframe_api";
        script.onload = () => {
          console.log('YouTube API script loaded');
          initializePlayer();
        };
        document.body.appendChild(script);
      }
    };

    loadYouTubeAPI();

    // Cleanup function to remove the YouTube iframe API script and destroy player
    return () => {
      // Ensure the script is present before trying to remove it
      const script = document.querySelector('script[src="https://www.youtube.com/iframe_api"]');
      if (script && document.body.contains(script)) {
        document.body.removeChild(script);
      }

      // Ensure the player exists before attempting to destroy it
      if (player) {
        player.destroy(); // Destroy the player when component unmounts or changes
      }
    };

  }, [videoData, player]); // Depend on videoData and player state

  // Fetch video data
  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await fetch("https://www.extendeddiscsolutions.org/wp-json/custom/v1/quiz", {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: videoId }),
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Fetched video data:', data);  // Log the fetched data
          setVideoData(data.status && data.data ? data.data : null);
        } else {
          console.error('Failed to fetch video data');
        }
      } catch (error) {
        console.error('Error fetching video data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVideoData();
  }, [videoId]);

  // Extract video ID from URL
  const extractVideoId = (url) => {
    if (!url) return null;

    const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:v\/|embed\/|watch\?v=))([^"&?/ ]{11})/);
    const videoId = match ? match[1] : null;

    console.log('Extracted videoId:', videoId); // Log the extracted videoId
    return videoId;
  };

  // Handle YouTube player state changes
  const handlePlayerStateChange = (event) => {
    console.log('Player state changed:', event.data);

    if (event.data === window.YT.PlayerState.ENDED && videoData && videoData.quizzes && videoData.quizzes[0]?.quiz_id) {
      console.log("Video ended, redirecting to quiz...");
      navigate(`/play-quiz/${videoId}`);  // Corrected syntax for dynamic URL
    }
  };

  // Use useLayoutEffect to ensure DOM is updated before initializing player
  useLayoutEffect(() => {
    if (videoData && youtubePlayerRef.current) {
      // Add a delay to ensure the div is available before initialization
      const timer = setTimeout(() => {
        initializePlayer(); // Manually initialize player if div and data are available
      }, 500); // 500ms delay to allow the div to be rendered

      // Cleanup timeout
      return () => clearTimeout(timer);
    }
  }, [videoData]); // Runs after videoData is updated

  return (
    <AdminLayout>
      <div className="bg-card">
        <div className="video-element">
          {/* Display a message for the quiz if video data exists */}
          {videoData && videoData.quizzes && videoData.quizzes[0]?.quiz_id && (
            <p>Complete the Quiz at the end of this video to track your progress and reinforce your coaching.</p>
          )}
          {/* Show a loading spinner while fetching video data */}
          {isLoading ? (
            <div className="loader"></div>
          ) : (
            <div>
              {/* Show the YouTube player only if video data is available */}
              {videoData && videoData.resource_name && (
                <div>
                  {/* Using ref to attach the div */}
                  <div ref={youtubePlayerRef}></div> {/* This is where the player will be injected */}
                </div>
              )}

            <button className="back-button" onClick={() => navigate(-1)}>Back</button>
            </div>
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default VideoPlayer;
