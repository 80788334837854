import { useState, useEffect } from "react";
import AdminLayout from "../components/common/AdminLayout";
import { useNavigate } from "react-router-dom";

const SalesProgram = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Overall loading state (for categories)
    const [isLoadingTips, setIsLoadingTips] = useState(false); // Loading state for tips
    const [error, setError] = useState(null); // Error state
    const [categories, setCategories] = useState([]); // Categories
    const [selectedCategory, setSelectedCategory] = useState(""); // Selected category
    const [tips, setTips] = useState([]); // Tips
    const [currentPage, setCurrentPage] = useState(1); // Current page for tips
    const [hasMoreTips, setHasMoreTips] = useState(false); // If more tips are available

    // Redirect to login if not logged in
    useEffect(() => {
        const loggedIn = localStorage.getItem("isLoggedIn");
        if (!loggedIn) {
            navigate("/login");
        }
    }, [navigate]);

    // Fetch categories for the program
    useEffect(() => {
        const fetchCategories = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(
                    "https://www.extendeddiscsolutions.org/wp-json/custom/v1/get_categories_by_program",
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ program_id: "sales_program" }),
                    }
                );

                if (!response.ok) {
                    throw new Error(`Failed to fetch categories: ${response.status}`);
                }

                const data = await response.json();

                if (data.status && data.data?.competences?.length > 0) {
                    setCategories(data.data.competences);
                    setSelectedCategory(data.data.competences[0].category_name); // Set the first category by default
                } else {
                    setError("No categories found.");
                }
            } catch (err) {
                setError("Failed to load categories.");
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };
        fetchCategories();
    }, []);

    useEffect(() => {
        if (!selectedCategory) return;

        const fetchTips = async () => {
            setIsLoadingTips(true);
            try {
                const response = await fetch(
                    "https://www.extendeddiscsolutions.org/wp-json/custom/v1/get_tips",
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            competency_name: selectedCategory,
                            program_id: "sales_program",
                            page: currentPage,
                        }),
                    }
                );

                const data = await response.json();

                if (data.status && data.data?.tips) {
                    setTips((prevTips) =>
                        currentPage === 1 ? data.data.tips : [...prevTips, ...data.data.tips]
                    );
                    setHasMoreTips(data.data.has_more);

                    // Automatically load more if content fits within the container
                    setTimeout(() => {
                        const container = document.getElementById("tips-container");
                        if (
                            container &&
                            container.scrollHeight <= container.clientHeight &&
                            data.data.has_more
                        ) {
                            setCurrentPage((prevPage) => prevPage + 1);
                        }
                    }, 500);
                } else {
                    setTips([]);
                }
            } catch (err) {
                setError("Failed to load tips.");
                console.error(err);
            } finally {
                setIsLoadingTips(false);
            }
        };

        fetchTips();
    }, [selectedCategory, currentPage]);

    // Handle category change
    const handleCategoryChange = (event) => {
        const selectedCategoryObj = categories.find(
            (category) => category.id === event.target.value
        );
        setSelectedCategory(selectedCategoryObj?.category_name || "");
        setCurrentPage(1); // Reset page when changing category
        setTips([]); // Clear current tips
    };

    // Handle scroll event for infinite scrolling
    const handleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        if (scrollTop + clientHeight >= scrollHeight - 50 && hasMoreTips && !isLoadingTips) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    return (
        <>
            <h3>Sales Program</h3>
            <p>
                Use the drop-down menu to access coaching on any of the range of
                competencies from your behavioral assessment. Inserted videos add
                insights, press on the links to open them.
            </p>
            <div className="bg-card">
                {error && <p style={{ color: "red" }}>{error}</p>}
                {isLoading && <div className="loader"></div>}
                {!isLoading && categories.length > 0 && (
                    <div>
                        {/* Dropdown for categories */}
                        <select
                            id="category-select"
                            value={categories.find((cat) => cat.category_name === selectedCategory)?.id || ""}
                            onChange={handleCategoryChange}
                            style={{ marginBottom: "1em" }}
                        >
                            {categories.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.category_name}
                                </option>
                            ))}
                        </select>

                        {/* Display tips with infinite scrolling */}
                        <div
                            id="tips-container"
                            onScroll={handleScroll}
                        >
                            {tips.map((tip) => (
                                <div key={tip.id} style={{ marginBottom: "1em" }}>
                                    <h5>{tip.category_name}</h5>
                                    <p>{tip.content}</p>
                                </div>
                            ))}
                            {isLoadingTips && <div className="loader"></div>}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default SalesProgram;
