import React from 'react';
import './components/css/Index.css';
import './components/css/style.css';
import './components/css/custom.css';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import User_Profile from './pages/User_Profile';
import Leadership_Report from './pages/Leadership_Report';
import AllReport from './pages/All_Report';
import SalesReport from './pages/Sales-Report';
import FocusAreas from './pages/Focus_Areas';
import InstantCoach from './pages/Instant_Coach';
import CompetencyDetails from './pages/CompetencyDetails';
import SalesResources from './pages/Sales_Resources';
import LeadershipResources from './pages/Leadership_Resources';
import DeepDive from './pages/DeepDive';
import Growth from './pages/Growth';
import Pitfalls from './pages/Pitfalls';
import LeadershipProgram from './pages/Leadership_Program';
import SalesProgram from './pages/Sales_Program';
import Notifications from './pages/Notifications';
import Tip_Of_The_Day from './pages/Tip_Of_The_Day';
import VideoPlayer from './pages/VideoPlayer';
import PlayQuiz from './pages/PlayQuiz';
import QuizResults from './pages/QuizResults';

import { Route, Routes, useLocation } from 'react-router-dom';

function App() {
  const location = useLocation();

  // Define the paths where the header and footer should be hidden
  const hideHeaderFooter = ['/login', '/dashboard', '/user-profile', '/leadership-report', '/all-report', '/sales-report', '/focus-areas', '/instant-coach','/competency-details','/sales-resources','/leadership-resources','/pitfalls','/growth','/deepdive','/leadership-program','/sales-program','/notifications','/tips','/video','/play-quiz','/quiz-result'].some(path =>
    location.pathname.startsWith(path)
  );
  return (
    <>
      {/* Conditionally render Header and Footer */}
      {!hideHeaderFooter && <Header />}
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="user-profile" element={<User_Profile />} />
        <Route path="leadership-report" element={<Leadership_Report />} />
        <Route path="all-report" element={<AllReport />} />
        <Route path="sales-report" element={<SalesReport />} />
        <Route path="focus-areas" element={<FocusAreas />} />
        <Route path="instant-coach" element={<InstantCoach />} />
        <Route path="competency-details" element={<CompetencyDetails />} />
        <Route path="sales-resources" element={<SalesResources />} />
        <Route path="leadership-resources" element={<LeadershipResources />} />
        <Route path="deepdive" element={<DeepDive />} />
        <Route path="pitfalls" element={<Pitfalls />} />
        <Route path="growth" element={<Growth />} />
        <Route path="leadership-program" element={<LeadershipProgram />} />
        <Route path="sales-program" element={<SalesProgram />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="tips" element={<Tip_Of_The_Day />} />
        <Route path="/video/:videoId" element={<VideoPlayer />} /> 
        <Route path="play-quiz/:videoId" element={<PlayQuiz />} /> 
        <Route path="/quiz-results/:quizId" element={<QuizResults />} />

        <Route path="/sales" element={<SalesResources />} replace />
        <Route path="/leadership" element={<LeadershipResources />} replace />
        <Route path="pitfalls" element={<DeepDive />} replace />
        <Route path="growth" element={<DeepDive />} replace />
        <Route path="/quick_connect" element={<InstantCoach />} replace />
        <Route path="/how_we_work" element={<Dashboard />} replace />
        <Route path="/resources" element={<SalesResources />} replace />
      </Routes>

      

      {/* Conditionally render Footer */}
      {!hideHeaderFooter && <Footer />}
    </>
  );
}

export default App;
