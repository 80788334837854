import React from 'react';

function Footer (){

    return (
    <>
        <footer class="footer"><p>© 2024 My Website. All rights reserved.</p></footer>
    </>

    );
}

export default Footer;