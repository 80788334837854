import { useState, useEffect } from 'react';
import AdminLayout from '../components/common/AdminLayout';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Tip_Of_The_Day = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); // Added loading state to track fetching status
    const [notifications, setNotifications] = useState([]); // State to store notifications

    useEffect(() => {
        const loggedIn = localStorage.getItem('isLoggedIn');
        if (!loggedIn) {
            navigate('/login'); // Redirect to login if not logged in
        }
    }, [navigate]);

    useEffect(() => {
        const getTips = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                if (!user || !user.user_id) {
                    console.error('Cannot update notification status: user ID is missing.');
                    return;
                }

                setLoading(true);

                const response = await axios.post(
                    'https://www.extendeddiscsolutions.org/wp-json/custom/v1/tip_of_the_day',
                    {
                        user_id: user.user_id,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (response.status === 200) {
                    // Access the notifications array inside the response data
                    setNotifications(response.data.data.notifications);
                } else {
                    console.error('Failed response:', response);
                }
            } catch (error) {
                console.error('Failed to get Tips:', error.response?.data || error.message);
            } finally {
                // Set loading to false once data is fetched
                setLoading(false);
            }
        };

        getTips(); // Call the function to fetch tips
    }, [navigate]);

    return (
        <AdminLayout>
            <h1>Tip of the Day</h1>
            {loading ? (
                <div class="loader"></div>  // Show loader while data is loading
            ) : notifications.length > 0 ? (
                <ul>
                    {notifications.map((notification, index) => (
                        <li key={index}>{notification}</li> // Display each notification
                    ))}
                </ul>
            ) : (
                <p>No Tips found.</p>
            )}
        </AdminLayout>
    );
};

export default Tip_Of_The_Day;
