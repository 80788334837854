import { useState, useEffect } from 'react';
import AdminLayout from '../components/common/AdminLayout';

const FocusAreas = () => {
    const [programs, setPrograms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedProgramId, setExpandedProgramId] = useState(null);
    const [categoriesData, setCategoriesData] = useState([]);
    const [hasMore, setHasMore] = useState({});
    const [page, setPage] = useState({}); // Track the current page for each program
    const [getComptency, setComptency] = useState(null);
    const [remainingDays, setRemainingDays] = useState(null); // New state for remaining_days
    const [selectedTab, setSelectedTab] = useState(categoriesData[0]?.programId);

    useEffect(() => {
        if (categoriesData.length > 0) {
          setSelectedTab(categoriesData[0].programId); // Default to first tab
        }
      }, [categoriesData]);

    useEffect(() => {
        const fetchActivatedProgramList = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const userId = user.user_id;

                const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/program_list', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: userId }),
                });

                const programResponse = await response.json();
                if (programResponse.status) {
                    const activatedPrograms = programResponse.data.activated_programs;
                    setPrograms(activatedPrograms);

                    // Set expandedProgramId to Leadership Program's ID (Assuming its name is 'Leadership Program')
                    const leadershipProgram = activatedPrograms.find(program => program.name === 'Leadership Program');
                    if (leadershipProgram) {
                        setExpandedProgramId(leadershipProgram.id);
                    }

                    const categoriesPromises = activatedPrograms.map(async (program) => {
                        const categoriesResponse = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/load-more-categories', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                user_id: userId,
                                program_id: program.id,
                                page: 1, // Initial page load
                            }),
                        });
                        const categoriesData = await categoriesResponse.json();

                        return {
                            programId: program.id,
                            programName: program.name,
                            categories: categoriesData.status ? categoriesData.data[0].categories : [],
                            hasMore: categoriesData.data[0].has_more, // Use has_more from the API
                        };
                    });

                    const allCategoriesData = await Promise.all(categoriesPromises);
                    setCategoriesData(allCategoriesData);
                } else {
                    console.error("Invalid Program ID");
                }
            } catch (error) {
                console.error("Error fetching focus areas:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchActivatedProgramList();
    }, []);

    useEffect(() => {
        const fetchActivatedProgramList = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const userId = user.user_id;

                const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/program_list', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: userId }),
                });

                const programResponse = await response.json();
                if (programResponse.status) {
                    const activatedPrograms = programResponse.data.activated_programs;
                    setPrograms(activatedPrograms);

                    const categoriesPromises = activatedPrograms.map(async (program) => {
                        const categoriesResponse = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/load-more-categories', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                user_id: userId,
                                program_id: program.id,
                                page: 1, // Initial page load
                            }),
                        });
                        const categoriesData = await categoriesResponse.json();

                        return {
                            programId: program.id,
                            programName: program.name,
                            categories: categoriesData.status ? categoriesData.data[0].categories : [],
                            hasMore: categoriesData.data[0].has_more, // Use has_more from the API
                        };
                    });

                    const allCategoriesData = await Promise.all(categoriesPromises);
                    setCategoriesData(allCategoriesData);
                } else {
                    console.error("Invalid Program ID");
                }
            } catch (error) {
                console.error("Error fetching focus areas:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchActivatedProgramList();
    }, []);

    const loadMoreCategories = async (programId) => {
        const nextPage = page[programId] ? page[programId] + 1 : 2;

        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const userId = user.user_id;

            const categoriesResponse = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/load-more-categories', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: userId,
                    program_id: programId,
                    page: nextPage,
                }),
            });

            const data = await categoriesResponse.json();

            if (data.status) {
                const newCategories = data.data[0].categories;
                const hasMoreData = data.data[0].has_more;

                setCategoriesData((prevData) => {
                    return prevData.map((programData) => {
                        if (programData.programId === programId) {
                            return {
                                ...programData,
                                categories: [...programData.categories, ...newCategories],
                                hasMore: hasMoreData,
                            };
                        }
                        return programData;
                    });
                });

                setPage((prevPage) => ({
                    ...prevPage,
                    [programId]: nextPage,
                }));
            }
        } catch (error) {
            console.error('Error loading more categories:', error);
        }
    };

    const toggleProgramExpansion = (programId) => {
        // If the clicked program is already expanded, do nothing.
        setSelectedTab(programId);
        if (expandedProgramId === programId) {
            return;
        }
        
        // Otherwise, set the clicked program's ID as expanded
        setExpandedProgramId(programId);
    };
    

    const handleCategorySelect = (programId, category) => {
        // Count total selected categories across all programs
        const totalSelectedCategories = categoriesData.reduce((count, programData) => {
            return count + programData.categories.filter((cat) => cat.selected).length;
        }, 0);
    
        setCategoriesData((prevData) =>
            prevData.map((programData) => {
                if (programData.programId === programId) {
                    const isSelected = programData.categories.some(
                        (cat) => cat.id === category.id && cat.selected
                    );
    
                    if (isSelected) {
                        // If already selected, deselect the category
                        return {
                            ...programData,
                            categories: programData.categories.map((cat) =>
                                cat.id === category.id ? { ...cat, selected: false } : cat
                            ),
                        };
                    } else if (totalSelectedCategories < 3) {
                        // Select the category if total selected count is less than 3
                        return {
                            ...programData,
                            categories: programData.categories.map((cat) =>
                                cat.id === category.id ? { ...cat, selected: true } : cat
                            ),
                        };
                    } else {
                        // Show alert if trying to select more than 3 categories
                        alert("You can select a maximum of 3 categories across all programs.");
                        return programData;
                    }
                }
                return programData;
            })
        );
    };

    useEffect(() => {
        const SelectedComptency = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const userId = user.user_id;

                const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/get-selected-categories', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user_id: userId,
                    }),
                });

                if (!response.ok) {
                    throw new Error(`API request failed with status ${response.status}`);
                }

                const data = await response.json();

                if (data.status) {
                    console.log(data);
                    setComptency(data.data); // Set only the data array here
                    setRemainingDays(data.remaining_days); // Set remaining_days
                } else {
                    console.log('No Competency selected!');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }finally {
                setLoading(false);
            }
        };

        SelectedComptency();
    }, []);
    

    const handleSubmit = async () => {
        const user = JSON.parse(localStorage.getItem('user'));
        const userId = user.user_id;

        const selectedData = categoriesData
            .filter((program) => program.categories.some((cat) => cat.selected))
            .map((program) => ({
                program_id: program.programId,
                program_name: program.programName,
                categories: program.categories
                    .filter((cat) => cat.selected)
                    .map((cat) => ({ id: cat.id, category_name: cat.category_name })),
            }));

        try {
            const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/update_selected_categories', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId, data: selectedData }),
            });

            if (response.ok) {
                window.location.reload();  // Refresh the page
            } else {
                alert("There was an issue submitting the categories.");
            }
        } catch (error) {
            console.error("Error submitting categories:", error);
        }
    };



    return (
        <AdminLayout>
            <h3 className="focus-area-title">Focus Areas</h3>
            <p>DISC AI Coach builds structure into the coaching experience by inviting coaches and coachees to identify and focus on specific areas of focus – guided by the behavioral assessment - for an agreed period of time. DISC AI Coach will send drip content daily to the coachee from the target areas and count down the allotted time. A prompt will be sent asking for the target areas to be revised or renewed.</p>
                
            {loading ? (
                <div className='bg-card'>
                    <div className="loader"></div> 
                </div>
            ) : (
                <>
                    {/* Conditional rendering based on whether selected competency data exists */}
                    {getComptency && getComptency.length > 0 ? (
                        <div className="selected-competency-data">
                          <div className='bg-card'>
                            <p><span>{remainingDays} days to go</span></p> {/* Display remaining_days */}
                            {getComptency.map((program, programIndex) => (
                                <div key={programIndex}>
                                    <h2>{program.program_name}</h2>
                                    {program.categories.map((category, categoryIndex) => (
                                        <div key={categoryIndex}>
                                            <p>
                                                <strong>■</strong> {category.category_name}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            ))}
                            </div>
                        </div>
                    ) : (
                        <div className='non-selected-competency-data'>
								{/* Render all program headers */}
                                <div className="tabs">
                                    <ul className="deep-options">
                                        {categoriesData.map((programData) => (
                                        <li
                                            key={programData.programId}
                                            className={`tab ${selectedTab === programData.programId ? 'active' : ''}`} 
                                            onClick={() => toggleProgramExpansion(programData.programId)}
                                        >
                                            {programData.programName}
                                        </li>
                                        ))}
                                    </ul>
                                </div>
		
								{/* Render the program list based on selected program */}
								<div className="tab-content">

                                    <div className='bg-card'>
									{categoriesData.map((programData) => (
										<div key={programData.programId} className="Program-list">
											{expandedProgramId === programData.programId && (
												<div className="competencies-data">
													{programData.categories.map((category) => (
														<div key={category.id} className="category-checkbox">
															<input
																type="checkbox"
																checked={category.selected || false}
																onChange={() => handleCategorySelect(programData.programId, category)}
															/>
															<label>{category.category_name}</label>
														</div>
													))}
													{programData.hasMore && (
														<div className="text-right"><button onClick={() => loadMoreCategories(programData.programId)} className="load-more-btn">Load More..</button></div>
													)}
												</div>
											)}
										</div>
									))}
                                </div>
								</div>
							
                            <button onClick={handleSubmit} className="submit-data" disabled={!categoriesData.some(program => program.categories.some(cat => cat.selected))}>
                                Submit
                            </button>
                        </div>
                    )}
                </>
            )}
        </AdminLayout>
    );
    
};

export default FocusAreas;
