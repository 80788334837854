import React, { useEffect } from 'react';
import AdminLayout from '../components/common/AdminLayout';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const AllReport = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const loggedIn = localStorage.getItem('isLoggedIn');
        if (!loggedIn) {
            navigate('/login'); // Redirect to login if not logged in
        }
    }, [navigate]);

    const user = JSON.parse(localStorage.getItem('user')) || {};
    console.log(user);

    return (
        <AdminLayout>
            <section class="assessments_listing">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="heading_assessments">
                            <h5> List of Assessments </h5>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <form class="form-inline search_form_right my-2 my-lg-0">
                            <input class="form-control ml-auto" type="search" placeholder="Search" />      
                            </form>
                        </div>
                    </div>
                    <div className="all-report-item bg-card">
                        <h6>Leadership Report</h6>  
                        <div class="row"> 
                            <div class="col-md-3">
                                <div class="outer_list_icon">
                                <div class="icon_assensment">
                                <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/Icon_list.svg" alt="icon_list" />
                                </div>
                                <div class="name_assensment">
                                <h5> { user.report_date } </h5>
                                <p>  </p>
                                </div>
                            </div>
                            </div>
                            <div class="col-md-7">
                                <div class="detail_assesment">
                                <p> { user.report_name } </p>
                                </div>
                            </div>
                        
                            <div class="col-md-2">
                                <div class="outer_list_icon last_icons">
                                <div class="icon_assensment">
                                    <Link to="/leadership-report" title="View Report">
                                        <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/eyedash.svg" alt="icon_list" />
                                    </Link></div><Link to="/leadership-report/" title="View Report">  
                                    </Link>
                                <div class="icon_assensment">
                                    <Link to="/generate-pdf/" title="Download Report">
                                        <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/dashbaord_download.svg" alt="icon_list" />
                                    </Link>
                                </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="all-report-item bg-card">
                        <h6>Sales Report</h6>
                        <div class="row"> 
                        <div class="col-md-3">
                            <div class="outer_list_icon">
                            <div class="icon_assensment">
                            <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/Icon_list.svg" alt="icon_list" />
                            </div>
                            <div class="name_assensment">
                            <h5> { user.report_date }  </h5>
                            <p>  </p>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-7">
                            <div class="detail_assesment">
                            <p> { user.report_name }</p>
                            </div>
                        </div>
                        
                        <div class="col-md-2">
                            <div class="outer_list_icon last_icons">
                            <div class="icon_assensment">
                                <Link to="/sales-report" title="View Report">
                                    <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/eyedash.svg" alt="icon_list" />
                                </Link></div><Link to="/sales-report" title="View Report">  
                                </Link>
                            <div class="icon_assensment">
                                <Link to="/generate-sales-pdf/" title="Download Report">
                                    <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/dashbaord_download.svg" alt="icon_list" />
                                </Link>
                            </div>
                            
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

            </section>
        </AdminLayout>
    );
};

export default AllReport;
