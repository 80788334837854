import React, { useEffect, useState } from 'react';
import AdminLayout from '../components/common/AdminLayout';
import { useNavigate } from 'react-router-dom';

const QuizResults = () => {
  const navigate = useNavigate();
  const [quizData, setQuizData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [quizResult, setQuizResult] = useState(null);

  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn');
    if (!loggedIn) {
      navigate('/login'); // Redirect to login if not logged in
    }
  }, [navigate]);

  useEffect(() => {
    const storedData = localStorage.getItem('quizResult');
    if (storedData) {
      setQuizResult(JSON.parse(storedData)); // Set quizResult when available
    } else {
      console.error('No quiz result found.');
    }
  }, []);

  // Only call fetchQuizResults if quizResult is available
  useEffect(() => {
    if (quizResult) {
      fetchQuizResults(); // Call fetchQuizResults only after quizResult is set
    }
  }, [quizResult]); // Run this effect when quizResult changes

  const fetchQuizResults = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const userId = user.user_id; // Get user ID from localStorage
      const videoId = quizResult.videoId; 
      const quizId = quizResult.quizId;
      
      console.log(videoId, quizId); // Check the values for videoId and quizId

      const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/get_quiz', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          resource_id: videoId,
          quiz_id: quizId,
        })
      });

      const data = await response.json();
      if (data.status) {
        console.log(data);
        setQuizData(data.data); // Store the quiz data in state
      }
    } catch (error) {
      console.error('Error fetching quiz data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Render options with checkmarks
  const renderOptions = (options, correctAnswer, userAnswer) => {
    const userAnswers = userAnswer[0].split('|').map((answer) => answer.trim()); // Split and trim user answer
    const correctAnswers = correctAnswer.map((answer) => answer.trim()); // Trim correct answers

    return options.map((option, index) => {
      const isCorrect = correctAnswers.includes(option.trim()); // Check if option is in correct answer
      const isUserAnswer = userAnswers.includes(option.trim()); // Check if option is in user answer

      return (
        <li key={index} className="option">
          <label>
            {option}
            <div className="checkbox">
              {/* Display green checkmark for correct answers */}
              {isCorrect && <span className="checkmark green">✔️</span>}
              {/* Display grey checkmark for user-selected answers */}
              {isUserAnswer && <span className="checkmark gray">✔️</span>}
            </div>
          </label>
        </li>
      );
    });
  };

  return (
    <AdminLayout>
      <div id="quiz-results" className="bg-card">
       <h2>Quiz Result</h2>
       <div class="user-note-section">
            <div class="user-note-content">
                    <span>Note:</span>
                    <p>User selection <span class="checkmark gray">✔️</span></p>
                    <p>Correct answer <span class="checkmark green">✔️</span></p>
            </div>
       </div>
        {loading ? (
          <p>Loading quiz results...</p>
        ) : (
          quizData.map((question) => (
            <div key={question.question_id} className="question-result">
              <h3>{question.question_name}</h3>
              <ul>
                {renderOptions(
                  question.options
                    .join('') // Join all option strings to avoid issues with splitting
                    .split('|'), // Split options by '|'
                  question.correct_answer, // Array of correct answers
                  question.user_answer // Array of user answers
                )}
              </ul>
            </div>
          ))
        )}
      </div>

      {/* Display total score for the quiz */}
      <div className="total-score">
        Total Score: {quizData.reduce((acc, question) => parseInt(question.total_score), 0)}
      </div>
      <button className="back-button" onClick={() => navigate(-1)}>Back</button>
    </AdminLayout>
  );
};

export default QuizResults;
