import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdminLayout from '../components/common/AdminLayout';

const PlayQuiz = () => {
  const { videoId } = useParams(); // Get the video ID from the URL params
  const navigate = useNavigate();
  const [videoData, setVideoData] = useState(null);
  const [quizData, setQuizData] = useState(null);
  const [userAnswers, setUserAnswers] = useState([]);
  const [isQuizVisible, setIsQuizVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn');
    if (!loggedIn) {
      navigate('/login'); // Redirect to login if not logged in
    }
  }, [navigate]);

  useEffect(() => {
    // Fetch video and quiz data when the videoId changes
    fetchVideoData();
  }, [videoId]);

  const fetchVideoData = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const userId = user.user_id; // Get user ID from localStorage
  
      const requestBody = JSON.stringify({
        id: videoId,
        user_id: userId,
      });
  
      const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/quiz', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: requestBody,
      });
  
      if (response.ok) {
        const data = await response.json();
  
        if (data.status && data.data && data.data.quizzes) {
          setVideoData(data.data); // Set video-related data
          setQuizData(data.data.quizzes[0]); // Assuming only one quiz per video
          setIsQuizVisible(true);
        } else {
          console.error("Quiz data is missing or malformed.");
        }
      } else {
        console.error("Failed to fetch video data", response.status);
      }
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  };

  const handleAnswerChange = (questionId, answer) => {
    // Check if the answer already exists for the given question
    const existingAnswer = userAnswers.find((ans) => ans.question_id === questionId);
    
    if (existingAnswer) {
      // Update the existing answer
      setUserAnswers((prevAnswers) =>
        prevAnswers.map((ans) =>
          ans.question_id === questionId ? { ...ans, user_answer: answer } : ans
        )
      );
    } else {
      // Add new answer
      setUserAnswers((prevAnswers) => [
        ...prevAnswers,
        { question_id: questionId, user_answer: answer },
      ]);
    }
  };

  const submitQuiz = async () => {
    try {
      setIsLoading(true);
  
      // Extract the necessary data
      const user = JSON.parse(localStorage.getItem('user'));
      const userId = user.user_id;
      const resourceId = videoId; // videoId is the resourceId
  
      // Log the videoData to check its structure
      console.log("videoData:", videoData);
  
      const resourceDetails = {
        resourceName: videoData?.video_title || 'Default Video Name',
        resourceType: "video", // Example, you can update this as needed
        resourceTitle: videoData?.video_title || 'Default Title',
        resourceDescription: videoData?.video_description || 'No description available.',
        resourceThumbnail: videoData?.video_thumbnail_url || 'default-thumbnail-url',
      };
  
      const quizzes = [quizData]; // Assuming only one quiz for this video
  
      const payload = {
        data: [
          {
            resource_id: resourceId,
            user_id: userId,
            resource_name: resourceDetails.resource_name,
            resource_type: resourceDetails.resource_type,
            resource_title: resourceDetails.resource_title,
            resource_description: resourceDetails.resource_description,
            resource_thumbnail: resourceDetails.resource_thumbnail,
            quizzes: quizzes.map((quiz) => ({
              quiz_id: quiz.quiz_id,
              quiz_title: quiz.quiz_title,
              quiz_description: quiz.quiz_description,
              quiz_url: quiz.quiz_url || '',
              questions: quiz.questions.map((question) => ({
                question_id: question.question_id,
                quiz_title: question.quiz_title || null,
                options: question.options || null,
                question: question.question,
                question_image: question.question_image || null,
                type: question.type,
                correct_answer: Array.isArray(question.correct_answer) 
                  ? question.correct_answer.join('|') 
                  : question.correct_answer,
                answers: Array.isArray(question.answers) 
                  ? question.answers.join('|') 
                  : question.answers,
                user_answer: userAnswers.find((answer) => answer.question_id === question.question_id)?.user_answer || '',
                user_quiz_answer: Array.isArray(question.user_quiz_answer) 
                  ? question.user_quiz_answer.join('|') 
                  : question.user_quiz_answer,
                date_attempt: question.date_attempt,
                quiz_result: question.quiz_result,
                question_name: question.question_name || null,
              })),
            })),
          },
        ],
      };
  
      console.log("Dynamic Payload sent to API:", payload);
  
      const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/update_quiz', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      const result = await response.json();
  
      console.log("API Response:", result);
  
      if (result.status) {
        localStorage.setItem('quizResult', JSON.stringify({ videoId, quizId: quizData.quiz_id }));
        // Navigate to the quiz result page, passing the quizId as a URL parameter
        navigate(`/quiz-results/${quizData.quiz_id}`, { replace: true });
      } else {
        alert('Failed to submit quiz! Reason: ' + (result.message || 'Unknown error'));
      }
    } catch (error) {
      console.error("Error submitting quiz:", error);
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <AdminLayout>
      <div className="quiz-container">
        {isQuizVisible && quizData ? (
          <div className="quiz">
            <h2 className="quiz-title"><span>Quiz - </span>{quizData.quiz_title}</h2>
            <div className="questions-container">
              {quizData.questions && quizData.questions.length > 0 ? (
                quizData.questions.map((question) => (
                  <div key={question.question_id} className="question-item">
                    <p className="question-text" dangerouslySetInnerHTML={{ __html: question.question }} />
                    <div className="answers">
                        {question.answers && question.answers.split('|').map((answer, index) => {
                          const isSelected = userAnswers.some(
                            (ans) => ans.question_id === question.question_id && ans.user_answer === answer
                          );
                          return (
                            <label
                              key={index}
                              className={`answer-label ${isSelected ? 'selected' : ''}`}
                            >
                              <input
                                type={question.type}
                                name={`question-${question.question_id}`}
                                value={answer}
                                onChange={() => handleAnswerChange(question.question_id, answer)}
                                className="answer-input"
                              />
                              {answer}
                            </label>
                          );
                        })}
                      </div>
                  </div>
                ))
              ) : (
                <p>No questions available.</p>
              )}
            </div>
            <button
              onClick={submitQuiz}
              disabled={isLoading}
              className={`submit-button ${isLoading ? 'loading' : ''}`}
            >
              {isLoading ? 'Submitting...' : 'Submit Quiz'}
            </button>
            <button className="back-button" onClick={() => navigate(-1)}>Back</button>
          </div>
          
        ) : (
          <div className="loader"></div>
        )}
       
      </div>
    </AdminLayout>
  );
};

export default PlayQuiz;
