import React, { useState } from 'react';

const MessagePopup = ({ mentorEmail, onClose, onSend }) => {
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const handleSend = () => {
        onSend(subject, message);
        onClose();
    };

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <h2>Send Message to Mentor</h2>
                <label>Subject:</label>
                <input
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                />
                <label>Message:</label>
                <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <button onClick={handleSend}>Send Message</button>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default MessagePopup;
