import { useState } from 'react';
import AdminLayout from '../components/common/AdminLayout';
import LeadershipProgram from '../pages/Leadership_Program';  // Import component
import SalesProgram from '../pages/Sales_Program';          // Import component
import Pitfalls from '../pages/Pitfalls';                  // Import component
import Growth from '../pages/Growth';                      // Import component

const DeepDive = () => {
    // State to track the selected tab
    const [selectedTab, setSelectedTab] = useState('leadership-program');

    // Function to handle tab change
    const handleTabClick = (tabName) => {
        setSelectedTab(tabName);
    };

    return (
        <div class="deep-dive">
        <AdminLayout>
            <div className="tabs">
                <ul className="deep-options">
                    {/* Tabs */}
                    <li
                        className={`tab ${selectedTab === 'leadership-program' ? 'active' : ''}`}
                        onClick={() => handleTabClick('leadership-program')}
                    >
                        Leadership Program
                    </li>
                    <li
                        className={`tab ${selectedTab === 'sales-program' ? 'active' : ''}`}
                        onClick={() => handleTabClick('sales-program')}
                    >
                        Sales Program
                    </li> 
                    <li
                        className={`tab ${selectedTab === 'pitfalls' ? 'active' : ''}`}
                        onClick={() => handleTabClick('pitfalls')}
                    >
                        Pitfalls
                    </li>
                    <li
                        className={`tab ${selectedTab === 'growth' ? 'active' : ''}`}
                        onClick={() => handleTabClick('growth')}
                    >
                        Growth
                    </li>
                </ul>
            </div>

            <div className="tab-content">
                {/* Content for Leadership Program */}
                {selectedTab === 'leadership-program' && <LeadershipProgram />}

                {/* Content for Sales Program */}
                {selectedTab === 'sales-program' && <SalesProgram />}

                {/* Content for Pitfalls */}
                {selectedTab === 'pitfalls' && <Pitfalls />}

                {/* Content for Growth */}
                {selectedTab === 'growth' && <Growth />}
            </div>
        </AdminLayout>
        </div>
    );
};

export default DeepDive;
