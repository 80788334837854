import { useState, useEffect } from 'react';
import AdminLayout from '../components/common/AdminLayout';
import { useNavigate } from 'react-router-dom';

const Pitfalls = () => {
    const navigate = useNavigate();
    const [videoData, setVideoData] = useState([]); // Store all loaded videos
    const [page, setPage] = useState(1); // Track current page
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [error, setError] = useState(null); // Error state
    const [hasMore, setHasMore] = useState(true); // If more pages are available
    const [toStyle, setToStyle] = useState(''); // Filter for "To Style"
    const [fromStyle, setFromStyle] = useState(''); // Filter for "From Style"

    useEffect(() => {
        const loggedIn = localStorage.getItem('isLoggedIn');
        if (!loggedIn) {
            navigate('/login'); // Redirect to login if not logged in
        }
    }, [navigate]);

    useEffect(() => {
        setPage(1); // Reset to the first page when filters change
        setVideoData([]); // Clear previous data
        loadVideos(1); // Load videos for page 1
    }, [toStyle, fromStyle]);

    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 200 &&
                !isLoading &&
                hasMore
            ) {
                setPage((prevPage) => prevPage + 1);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isLoading, hasMore]);

    useEffect(() => {
        if (page > 1) {
            loadVideos(page);
        }
    }, [page]);

    const loadVideos = async (currentPage) => {
        setIsLoading(true);
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const userId = user.user_id;

            const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/resources', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'video',
                    category_name: 'pitfalls',
                    user_id: userId,
                    search: '',
                    content_type: fromStyle,
                    page: currentPage,
                }),
            });

            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }

            const data = await response.json();
            if (data.status && Array.isArray(data.data)) {
                setVideoData((prevData) =>
                    currentPage === 1 ? data.data : [...prevData, ...data.data]
                );
                setHasMore(data.pagination.has_more);
            } else {
                setError("No videos found or status is false.");
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching videos:", error);
            setError("Failed to load videos.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleToStyleChange = (e) => {
        setToStyle(e.target.value);
    };

    const handleFromStyleChange = (e) => {
        setFromStyle(e.target.value);
    };

    const getShortDescription = (description) => {
        const words = description.split(' ');
        return words.slice(0, 10).join(' ') + (words.length > 10 ? '...' : '');
    };

    return (
        <>
            <AdminLayout>
                <h3>Pitfalls</h3>
                <p>Access a range of mind-expanding, skill-upgrading content. Click the video icon to open the search and funnel filters.</p>
                <div className='filter-block'>
                    <div className='filter-right-block'>
                        <label>From Style</label>
                        <select value={fromStyle} onChange={handleFromStyleChange}>
                            <option value="">Select From Style</option>
                            <option value="D">Dominance (D)</option>
                            <option value="I">Influence (I)</option>
                            <option value="S">Steadiness (S)</option>
                            <option value="C">Conscientiousness (C)</option>
                            <option value="All">All</option>
                        </select>
                    </div>
                </div>
                <div className="video-gallery">
                    {videoData.length > 0 ? (
                        videoData.map((video) => (
                            <div className="video-card" key={video.id}>
                                <a href={video.name} target="_blank" rel="noopener noreferrer">
                                    <img src={video.thumbnail} alt={video.title} className="video-thumbnail" />
                                </a>
                                <h3 className="video-title">{video.title}</h3>
                                <p className="description">{getShortDescription(video.description)}</p>
                            </div>
                        ))
                    ) : (
                        !isLoading && <p>No videos available.</p>
                    )}
                </div>
                {isLoading && <div className="loader"></div>}
            </AdminLayout>
        </>
    );
};

export default Pitfalls;
